import { ApiHelper } from '@/utils';

import { IUser, IUserInList, IParamChangeStatusUser, IUserDetail, IUserComp } from './user.interface';
import { IListResponse, IQueryParam, IResponse } from '../common.services';
import { ApiHelperNadmin } from '@/utils/httpNadmin';

class UserGroupServiceRoute {
  // Get profile
  static readonly GET_USER_PROFILE = '/admin/me';
  //

  static readonly LIST_USER = '/users';

  static readonly CHANGE_STATUS_USER = '/users/change-status';

  static readonly TOTAL_USER = '/users/total';

  static readonly USERS_COMP = '/affiliate/users/company-profile';
}

export const getUserProfile = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IResponse<IUser>>(UserGroupServiceRoute.GET_USER_PROFILE, undefined, query);
};

export const getListUser = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IUserInList>>(UserGroupServiceRoute.LIST_USER, undefined, query);
};

export const changeStatusUser = async (payload?: IParamChangeStatusUser) => {
  return ApiHelper.put<IResponse<IUserInList>, any>(UserGroupServiceRoute.CHANGE_STATUS_USER, payload);
};

export const getTotalUser = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IResponse<{ totalUser: number }>>(UserGroupServiceRoute.TOTAL_USER, undefined, query);
};

export const getUserDetail = async (id: number) => {
  return ApiHelper.get<IResponse<IUserDetail>>(`${UserGroupServiceRoute.LIST_USER}/${id}`);
};

export const getUserCompProfile = async (query?: IQueryParam[]) => {
  return ApiHelperNadmin.get<IResponse<IUserComp>>(UserGroupServiceRoute.USERS_COMP, undefined, query);
};

import React from 'react';

import { LoginLayout, MainLayout } from '../layouts';
import {
  ADMIN_TRANSACTION_HISTORY_ROUTER,
  BALANCE_REPORT_ROUTER,
  COMMISSION_CLAIM_ROUTER,
  COMMISSION_REPORT_ROUTER,
  DASHBOARD_ROUTER,
  HISTORY_LIST_ROUTER,
  MARKETING_COMPANY_DETAIL_ROUTER,
  MARKETING_COMPANY_ROUTER,
  MEMBER_DETAIL_ROUTER,
  MEMBER_LIST_ROUTER,
  SALE_REPORT_ROUTER,
  TRANSACTION_REQUEST_TRACKING_ROUTER,
  USER_DETAIL_ROUTER,
  USER_LIST_ROUTER,
  USER_TRANSACTION_HISTORY_ROUTER,
  VIP_PACKAGE_TRAKING_ROUTER,
  WALLET_LIST_ROUTER,
  WITHDRAWAL_REQUESTS_ROUTER,
} from './constants';
import { ERoleAdmin } from '@/constants';

//no auth component
const Login = React.lazy(() => import('@/pages/sign-in'));
const ForgotPassword = React.lazy(() => import('@/pages/forgot-password'));
const ResetPassword = React.lazy(() => import('@/pages/reset-password'));
const ConfirmCode = React.lazy(() => import('@/pages/confirm-code'));
//

//auth component
const Dashboard = React.lazy(() => import('@/pages/user-management/dashboard'));
const UserList = React.lazy(() => import('@/pages/user-management/user-list'));
const UserDetail = React.lazy(() => import('@/pages/user-management/user-detail'));
const MemberList = React.lazy(() => import('@/pages/user-management/member-list'));
const MemberDetail = React.lazy(() => import('@/pages/user-management/member-detail'));

const CommissionClaim = React.lazy(() => import('@/pages/affiliate-marketing/commission-claim'));
const MarketingCompany = React.lazy(() => import('@/pages/affiliate-marketing/marketing-company'));
const MarketingCompanyDetail = React.lazy(() => import('@/pages/affiliate-marketing/marketing-company-detail'));
const WithdrawalRequests = React.lazy(() => import('@/pages/request-tracking/withdrawal-requests'));
const CommissionReport = React.lazy(() => import('@/pages/reports/commission-report'));
const BalanceReport = React.lazy(() => import('@/pages/reports/balance-report'));
const SaleReport = React.lazy(() => import('@/pages/reports/sale-report'));

const HistoryList = React.lazy(() => import('@/pages/history-log/history-list'));
const VipPackageTracking = React.lazy(() => import('@/pages/request-tracking/vip-package-tracking'));

const TransactionRequestTracking = React.lazy(() => import('@/pages/hot-wallet/transaction-request-tracking'));
const UserTransactionHistory = React.lazy(() => import('@/pages/hot-wallet/user-transaction-history'));
const AdminTransactionHistory = React.lazy(() => import('@/pages/hot-wallet/admin-transaction-history'));
const WalletList = React.lazy(() => import('@/pages/hot-wallet/wallet-list'));

export interface IRoute {
  Component: ((props: any) => JSX.Element) | React.FC<any>;
  Layout: ((props: any) => JSX.Element) | React.FC<any>;
  Protected: boolean;
  isHelpcenter?: boolean;
  path?: string | string[];
  routePath?: string;
  from?: string;
  to?: string;
  exact?: boolean;
  acceptRole?: string[];
  roles: string[];
}

const noAuthRoute: IRoute[] = [
  {
    Component: Login,
    Layout: LoginLayout,
    exact: true,
    path: '/sign-in',
    routePath: '/sign-in',
    Protected: false,
    roles: [],
  },
  {
    Component: ForgotPassword,
    Layout: LoginLayout,
    exact: true,
    path: '/forgot-password',
    routePath: '/forgot-password',
    Protected: false,
    roles: [],
  },
  {
    Component: ConfirmCode,
    Layout: LoginLayout,
    exact: true,
    path: '/confirm-code',
    routePath: '/confirm-code',
    Protected: false,
    roles: [],
  },

  {
    Component: ResetPassword,
    Layout: LoginLayout,
    exact: true,
    path: '/reset-password',
    routePath: '/reset-password',
    Protected: false,
    roles: [],
  },
];

const authRouter: IRoute[] = [
  /** User management **/

  //Dashboard
  {
    Component: Dashboard,
    Layout: MainLayout,
    exact: true,
    path: [DASHBOARD_ROUTER],
    routePath: DASHBOARD_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
  },

  //User list
  {
    Component: UserList,
    Layout: MainLayout,
    exact: true,
    path: [USER_LIST_ROUTER],
    routePath: USER_LIST_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
  },
  {
    Component: UserDetail,
    Layout: MainLayout,
    exact: true,
    path: [USER_DETAIL_ROUTER],
    routePath: USER_DETAIL_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
  },
  //

  //Member list
  {
    Component: MemberList,
    Layout: MainLayout,
    exact: true,
    path: [MEMBER_LIST_ROUTER],
    routePath: MEMBER_LIST_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
  },
  {
    Component: MemberDetail,
    Layout: MainLayout,
    exact: true,
    path: [MEMBER_DETAIL_ROUTER],
    routePath: MEMBER_DETAIL_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
  },
  //

  /** Affiliate marketing */

  //Commission claim
  {
    Component: CommissionClaim,
    Layout: MainLayout,
    exact: true,
    path: [COMMISSION_CLAIM_ROUTER],
    routePath: COMMISSION_CLAIM_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //Marketing company
  {
    Component: MarketingCompany,
    Layout: MainLayout,
    exact: true,
    path: [MARKETING_COMPANY_ROUTER],
    routePath: MARKETING_COMPANY_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  {
    Component: MarketingCompanyDetail,
    Layout: MainLayout,
    exact: true,
    path: [MARKETING_COMPANY_DETAIL_ROUTER],
    routePath: MARKETING_COMPANY_DETAIL_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  /** Request tracking */

  //Withdrawal requests
  {
    Component: WithdrawalRequests,
    Layout: MainLayout,
    exact: true,
    path: [WITHDRAWAL_REQUESTS_ROUTER],
    routePath: WITHDRAWAL_REQUESTS_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //Vip package traking
  {
    Component: VipPackageTracking,
    Layout: MainLayout,
    exact: true,
    path: [VIP_PACKAGE_TRAKING_ROUTER],
    routePath: VIP_PACKAGE_TRAKING_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  /** Hot wallet */

  //Transaction request tracking
  {
    Component: TransactionRequestTracking,
    Layout: MainLayout,
    exact: true,
    path: [TRANSACTION_REQUEST_TRACKING_ROUTER],
    routePath: TRANSACTION_REQUEST_TRACKING_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //User transaction history
  {
    Component: UserTransactionHistory,
    Layout: MainLayout,
    exact: true,
    path: [USER_TRANSACTION_HISTORY_ROUTER],
    routePath: USER_TRANSACTION_HISTORY_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //Admin transaction history
  {
    Component: AdminTransactionHistory,
    Layout: MainLayout,
    exact: true,
    path: [ADMIN_TRANSACTION_HISTORY_ROUTER],
    routePath: ADMIN_TRANSACTION_HISTORY_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //Wallet list
  {
    Component: WalletList,
    Layout: MainLayout,
    exact: true,
    path: [WALLET_LIST_ROUTER],
    routePath: WALLET_LIST_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  /** Reports */

  //Balance report
  {
    Component: BalanceReport,
    Layout: MainLayout,
    exact: true,
    path: [BALANCE_REPORT_ROUTER],
    routePath: BALANCE_REPORT_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //Sale report
  {
    Component: SaleReport,
    Layout: MainLayout,
    exact: true,
    path: [SALE_REPORT_ROUTER],
    routePath: SALE_REPORT_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //Commission report
  {
    Component: CommissionReport,
    Layout: MainLayout,
    exact: true,
    path: [COMMISSION_REPORT_ROUTER],
    routePath: COMMISSION_REPORT_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },
  //

  //History list
  {
    Component: HistoryList,
    Layout: MainLayout,
    exact: true,
    path: [HISTORY_LIST_ROUTER],
    routePath: HISTORY_LIST_ROUTER,
    Protected: true,
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
  },

  //
];

export const routes = [...noAuthRoute, ...authRouter];

import i18n from '@/i18n';
import { BusdtIcon, BnbIcon, TetherIcon, EthIcon, TrxIcon } from '@/assets';

export const DEFAULT_MONTH_FILTER = 12;

export enum MoneyDefault {
  USDT = 'USDT',
  BTC = 'BTC',
  KRW = 'KRW',
}

export enum StatusRequest {
  PENDING = 1,
  APPROVED,
  REJECTED,
}

export enum FormatTime {
  Date = 'DD/MM/YYYY',
  DateFull = 'DD/MM/YYYY HH:mm:ss',
  MonthYear = 'MM/YYYY',
}

export enum StatusVipPackageKeys {
  PENDING = 1,
  ACTIVE = 2,
  EXPIRED = 3,
}
export const listStatusVipPackage = [
  {
    id: 1,
    key: StatusVipPackageKeys.ACTIVE,
    name: i18n.t('Package created'),
    color: '#039855',
  },
  {
    id: 2,
    key: StatusVipPackageKeys.PENDING,
    name: i18n.t('Pending'),
    color: '#DC6803',
  },
  {
    id: 3,
    key: StatusVipPackageKeys.EXPIRED,
    name: i18n.t('Expired'),
    color: '#F04438',
  },
];
export const ObjStatusVipPackage = {
  label: i18n.t('Module'),
  filterKey: 'module',
  items: [
    {
      name: 'All',
      key: '',
    },
    ...listStatusVipPackage,
  ],
};

export enum StatusUser {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  LOCKED = 'locked',
}

export const listStatusUser = [
  {
    id: 1,
    key: 'inactive',
    name: 'Inactive',
  },
  {
    id: 2,
    key: 'active',
    name: 'Active',
  },
  {
    id: 3,
    key: 'locked',
    name: 'Locked',
  },
];

export enum EUserRole {
  CEO = 'MARKETING_COMPANY',
  DIVISION_LEADER = 'DIVISION_LEADER',
  TEAM_LEADER = 'TEAM_LEADER',
  SUB_AFFILIATE = 'SUB_AFFILIATE',
}

export enum StatusKeys {
  Inactive = 'inactive',
  Active = 'active',
}

export const listStatusSubAffilicate = [
  {
    id: 1,
    key: StatusKeys.Active,
    name: 'Active',
  },
  {
    id: 2,
    key: StatusKeys.Inactive,
    name: 'Disabled',
  },
];

export enum StatusClaimRequestKeys {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export const listStatusClaimRequest = [
  {
    id: 1,
    key: StatusClaimRequestKeys.Approved,
    name: 'Approved',
    color: '#12B76A',
  },
  {
    id: 2,
    key: StatusClaimRequestKeys.Pending,
    name: 'Pending',
    color: '#FDB022',
  },
  {
    id: 3,
    key: StatusClaimRequestKeys.Rejected,
    name: 'Rejected',
    color: '#F04438',
  },
];

export const ObjStatusClaimRequestFilter = {
  label: 'Status',
  filterKey: 'status',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Approved',
      key: StatusClaimRequestKeys.Approved,
    },
    {
      name: 'Rejected',
      key: StatusClaimRequestKeys.Rejected,
    },
    {
      name: 'Pending',
      key: StatusClaimRequestKeys.Pending,
    },
  ],
};

export enum PositionSubAffiliateType {
  DIVISION_LEADER = 'DIVISION_LEADER',
  TEAM_LEADER = 'TEAM_LEADER',
  SUB_AFFILIATE = 'SUB_AFFILIATE',
}

export const listPositionSubAffilicate = [
  {
    id: 1,
    key: PositionSubAffiliateType.DIVISION_LEADER,
    name: 'Sale division manager',
    acceptRole: [EUserRole.CEO],
  },
  {
    id: 2,
    key: PositionSubAffiliateType.TEAM_LEADER,
    name: 'Sale team leader',
    acceptRole: [EUserRole.DIVISION_LEADER, EUserRole.CEO],
  },
  {
    id: 3,
    key: PositionSubAffiliateType.SUB_AFFILIATE,
    name: 'Sale staff',
    acceptRole: [EUserRole.DIVISION_LEADER, EUserRole.CEO, EUserRole.TEAM_LEADER],
  },
];

export enum StatusVipPackageRequest {
  PENDING = 1,
  ACTIVE,
  EXPIRED,
}

export enum ERoleAdmin {
  SUPER_ADMIN = 'superadmin',
  TOP_TRADING_ADMIN = 'top_trading_admin',
  MARKETING_ADMIN = 'marketing',
}

export enum EBotPackageType {
  BASIC = '1',
  ADVANCE = '2',
  PREMIUM = '3',
  VIP = '4',
  PROFIT_SHARING = '5',
}

export enum EOrganizationPosition {
  NORMAL_USER = 'Titan Trading',
  STAFF = 'TT Bot',
  JUNIOR_MANAGER = 'TT Bot',
  TEAM_MANAGER = 'TT Bot',
  CENTER_DIRECTOR = 'TT Bot',
  REGIONAL_HEADQUATER_MANAGER = 'TT Bot',
}

export enum EOrganizationPositionTitle {
  NORMAL_USER = 'Normal user',
  STAFF = 'Staff',
  JUNIOR_MANAGER = 'Junior Manager',
  TEAM_MANAGER = 'Team Manager',
  CENTER_DIRECTOR = 'Center Director',
  REGIONAL_HEADQUATER_MANAGER = 'Regional Head Quater Manager',
}

export enum EPlatform {
  TITAN = 'titan',
  TOP_TRADING = 'top-trading',
}

export const ListSite = [
  { id: EPlatform.TITAN, name: 'Titan Trading' },
  { id: EPlatform.TOP_TRADING, name: 'Top Trading' },
];

export enum SelectApiType {
  DIVISION = 'DIVISION',
  TEAM = 'TEAM',
}

export const ObjPositionFilter = {
  label: 'Position',
  filterKey: 'position',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Sale division manager',
      key: PositionSubAffiliateType.DIVISION_LEADER,
    },
    {
      name: 'Sale team leader',
      key: PositionSubAffiliateType.TEAM_LEADER,
    },
    {
      name: 'Sale staff',
      key: PositionSubAffiliateType.SUB_AFFILIATE,
    },
  ],
};

export const ObjStatusFilter = {
  label: 'Status',
  filterKey: 'status',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Active',
      key: StatusKeys.Active,
    },
    {
      name: 'Disabled',
      key: StatusKeys.Inactive,
    },
  ],
};

export const ObjRangeDateTransactionFilter = {
  label: 'Transaction time',
  filterKey: ['startTime', 'endTime'],
  type: 'date',
};

export const ObjRangeDateTimeFilter = {
  label: 'Time',
  filterKey: ['startTime', 'endTime'],
  type: 'date',
};

export const ObjMemberGradeFilter = {
  label: 'Member grade',
  filterKey: 'packageType',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Profit sharing member',
      key: EBotPackageType.PROFIT_SHARING,
    },
    {
      name: 'Basic member',
      key: EBotPackageType.BASIC,
    },
    {
      name: 'Advanced member',
      key: EBotPackageType.ADVANCE,
    },
    {
      name: 'Premium member',
      key: EBotPackageType.PREMIUM,
    },
    {
      name: 'VIP member',
      key: EBotPackageType.VIP,
    },
  ],
};

export enum BotShowType {
  PURCHASE_PACKAGE = 1,
  PROFIT_SHARING,
}

export enum ReferredUserChartPackageColor {
  BASIC = '#77D6AC',
  ADVANCE = '#5709DD',
  PREMIUM = '#86C4FF',
  VIP = '#858E9D',
  PROFIT_SHARING = '#FCD434',
}

export enum TransactionType {
  DEPOSIT = 1,
  WITHDRAW = 2,
  LOCK_IN_PROFIT_SHARING = 3,
  PURCHASE_PACKAGE = 4,
  COMMISSION = 5,
  PACKAGE_REFUND = 6,
  PROFIT_SHARING_REFUND = 7,
  COMMISSION_PAID = 8,
}

export const ObjCategoryFilter = {
  label: 'Transaction',
  filterKey: 'type',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Withdrawal',
      key: TransactionType.WITHDRAW,
    },
    {
      name: 'Commission claim',
      key: TransactionType.COMMISSION,
    },
    {
      name: 'Commission paid',
      key: TransactionType.COMMISSION_PAID,
    },
    {
      name: 'Lock amount refunded',
      key: TransactionType.PROFIT_SHARING_REFUND,
    },
  ],
};

export enum CommissionReportChartColor {
  PURCHASE_PKG_OF_AFFILIATE = '#5709DD',
  PURCHASE_PKG_OF_COMMISSION_LEFT = '#AF86F7',
  PROFIT_SHARING_AFFILIATE = '#FCD434',
  PROFIT_SHARING_COMMISSION_LEFT = '#FCE89A',
}

export enum ENetwork {
  BEP20 = 1,
  ERC20,
  TRC20,
}

export const listNetWork = [
  {
    id: 1,
    key: ENetwork.BEP20,
    name: 'Binance Smart Chain',
  },
  {
    id: 2,
    key: ENetwork.ERC20,
    name: 'Ethereum',
  },
  {
    id: 3,
    key: ENetwork.TRC20,
    name: 'Tron',
  },
];

export enum EAcitivityLogModule {
  USER_LIST = 'USER_LIST',
  WITHDRAWAL_REQUEST = 'WITHDRAWAL_REQUEST',
  VIP_PACKAGE_TRACKING = 'VIP_PACKAGE_TRACKING',
  TRANSACTION_REQUEST_TRACKING = 'TRANSACTION_REQUEST_TRACKING',
  MKT_COMPANY = 'MKT_COMPANY',
  COMMISION_CLAIM = 'COMMISION_CLAIM',
}

export const listAcitivityLogModule = [
  {
    id: 1,
    key: EAcitivityLogModule.USER_LIST,
    name: i18n.t('User list'),
  },
  {
    id: 2,
    key: EAcitivityLogModule.WITHDRAWAL_REQUEST,
    name: i18n.t('Withdrawal request'),
  },
  {
    id: 3,
    key: EAcitivityLogModule.VIP_PACKAGE_TRACKING,
    name: i18n.t('VIP package tracking'),
  },
  {
    id: 4,
    key: EAcitivityLogModule.TRANSACTION_REQUEST_TRACKING,
    name: i18n.t('Transaction request tracking'),
  },
  {
    id: 5,
    key: EAcitivityLogModule.MKT_COMPANY,
    name: i18n.t('Marketing company'),
  },
  {
    id: 6,
    key: EAcitivityLogModule.COMMISION_CLAIM,
    name: i18n.t('Commission claim'),
  },
];

export const ObjAcitivityLogModule = {
  label: i18n.t('Module'),
  filterKey: 'module',
  items: [
    {
      name: 'All',
      key: '',
    },
    ...listAcitivityLogModule,
  ],
};

export const listToken = [
  {
    id: 1,
    name: 'USDT',
    value: 'USDT',
    icon: TetherIcon,
  },
  {
    id: 2,
    name: 'BUSDT',
    value: 'BUSDT',
    icon: BusdtIcon,
  },
  {
    id: 3,
    name: 'BNB',
    value: 'BNB',
    icon: BnbIcon,
  },
  {
    id: 4,
    name: 'TRX',
    value: 'TRX',
    icon: TrxIcon,
  },
  {
    id: 5,
    name: 'ETH',
    value: 'ETH',
    icon: EthIcon,
  },
];

export const netWorks = [
  {
    id: 1,
    name: 'BEP20',
    value: 1,
  },
  // {
  //   id: 2,
  //   name: 'ERC20',
  //   value: 2,
  // },
  // {
  //   id: 3,
  //   name: 'TRC20 (only USDT)',
  //   value: 3,
  // },
];

export enum StatusTransactionRequestKeys {
  WAITING = 1,
  INPROGRESS,
  APPROVED,
  REJECT,
  EXPIRED,
}
export const listStatusTransactionRequest = [
  {
    id: 1,
    key: StatusTransactionRequestKeys.APPROVED,
    name: i18n.t('Approved'),
    color: '#039855',
  },
  {
    id: 2,
    key: StatusTransactionRequestKeys.REJECT,
    name: i18n.t('Rejected'),
    color: '#F04438',
  },
  {
    id: 3,
    key: StatusTransactionRequestKeys.EXPIRED,
    name: i18n.t('Expired'),
    color: '#F04438',
  },
  {
    id: 4,
    key: StatusTransactionRequestKeys.INPROGRESS,
    name: i18n.t('In progress'),
    color: '#DC6803',
  },
  {
    id: 5,
    key: StatusTransactionRequestKeys.WAITING,
    name: i18n.t('Pending'),
    color: '#DC6803',
  },
];
export const ObjStatusTransactionRequest = {
  label: i18n.t('Status'),
  filterKey: 'status',
  items: [
    {
      name: 'All',
      key: '',
    },
    ...listStatusTransactionRequest,
  ],
};

export enum StatusApproveRequestKeys {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export const listStatusApproveRequest = [
  {
    id: 1,
    key: StatusApproveRequestKeys.Approved,
    name: 'Approved',
    color: '#12B76A',
  },
  {
    id: 2,
    key: StatusApproveRequestKeys.Pending,
    name: 'Pending',
    color: '#FDB022',
  },
  {
    id: 3,
    key: StatusApproveRequestKeys.Rejected,
    name: 'Rejected',
    color: '#F04438',
  },
];

export const listTransactionType = [
  {
    id: 1,
    key: TransactionType.DEPOSIT,
    name: i18n.t('Deposit'),
  },
  {
    id: 2,
    key: TransactionType.WITHDRAW,
    name: i18n.t('Withdrawal'),
  },
];
export const ObjTransactionType = {
  label: i18n.t('Transaction type'),
  filterKey: 'type',
  items: [
    {
      name: 'All',
      key: '',
    },
    ...listTransactionType,
  ],
};

export enum ETransactionStatus {
  COMPLETED = 1,
  FAILED,
}

export const listTransactionStatus = [
  {
    id: 1,
    key: ETransactionStatus.COMPLETED,
    name: i18n.t('Completed'),
    color: '#12B76A',
  },
  {
    id: 2,
    key: ETransactionStatus.FAILED,
    name: i18n.t('Failed'),
    color: '#F04438',
  },
];

export const ObjTransactionStatus = {
  label: i18n.t('Status'),
  filterKey: 'status',
  items: [
    {
      name: 'All',
      key: '',
    },
    ...listTransactionStatus,
  ],
};

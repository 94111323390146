// user management
export const USER_MANAGEMENT = '/user-management';
export const DASHBOARD_ROUTER = USER_MANAGEMENT + '/dashboard';
export const USER_LIST_ROUTER = USER_MANAGEMENT + '/user-list';
export const USER_DETAIL_ROUTER = USER_LIST_ROUTER + '/user-detail';
export const MEMBER_LIST_ROUTER = USER_MANAGEMENT + '/member-list';
export const MEMBER_DETAIL_ROUTER = MEMBER_LIST_ROUTER + '/member-detail';

// affiliate marketing
export const AFFILIATE_MARKETING_ROUTER = '/affiliate-marketing';
export const COMMISSION_CLAIM_ROUTER = AFFILIATE_MARKETING_ROUTER + '/commission-claim';
export const MARKETING_COMPANY_ROUTER = AFFILIATE_MARKETING_ROUTER + '/marketing-company-list';
export const MARKETING_COMPANY_DETAIL_ROUTER = MARKETING_COMPANY_ROUTER + '/marketing-company-detail';

// request tracking
export const REQUEST_TRACKING_ROUTER = '/request-tracking';
export const WITHDRAWAL_REQUESTS_ROUTER = REQUEST_TRACKING_ROUTER + '/withdrawl-requests';
export const VIP_PACKAGE_TRAKING_ROUTER = REQUEST_TRACKING_ROUTER + '/vip-package-tracking';

// reports
export const REPORTS_ROUTER = '/reports';
export const COMMISSION_REPORT_ROUTER = REPORTS_ROUTER + '/commission-report';
export const BALANCE_REPORT_ROUTER = REPORTS_ROUTER + '/balance-report';
export const SALE_REPORT_ROUTER = REPORTS_ROUTER + '/sale-report';

// History log
export const HISTORY_LOG_ROUTER = '/history-log';
export const HISTORY_LIST_ROUTER = HISTORY_LOG_ROUTER + '/history-list';

// Hot wallet
export const HOT_WALLET = '/hot-wallet';
export const TRANSACTION_REQUEST_TRACKING_ROUTER = HOT_WALLET + '/transaction-request-tracking';
export const USER_TRANSACTION_HISTORY_ROUTER = HOT_WALLET + '/users-transaction-history';
export const ADMIN_TRANSACTION_HISTORY_ROUTER = HOT_WALLET + '/admins-transaction-history';
export const WALLET_LIST_ROUTER = HOT_WALLET + '/wallet-list';

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import { RouteWithLayout } from '@/components/RouteWithLayout';
import { requestUserProfile } from '@/store/user/actions';

import 'antd/dist/antd.less';
import './App.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';
import { DialogError } from './components';
import './i18n';
import { routes } from './routes';
import { IsLoggedIn } from './store/auth/selector';
export default function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(IsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(requestUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      <Switch>
        <Redirect from="/" to={'/sign-in'} exact />

        {routes.map(({ Component, Layout, Protected, routePath, exact, path, roles }, key) => {
          return (
            <RouteWithLayout
              key={String(key + 1)}
              component={Component}
              exact={exact}
              layout={Layout}
              path={path}
              routePath={routePath}
              protect={Protected}
              roles={roles}
            />
          );
        })}
      </Switch>
      <DialogError />
    </>
  );
}

import React, { Suspense, useCallback } from 'react';

import { routes } from '@/routes';
import { IsLoggedIn } from '@/store/auth/selector';
import { commonRequestError } from '@/store/common/actions';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';

export interface RouteWithLayoutProps {
  component: React.FC<any>;
  layout: React.FC<any>;
  path?: string | string[];
  from?: string;
  to?: string;
  exact?: boolean;
  protect: boolean;
  routePath?: string;
  roles?: any[];
}

export const RouteWithLayout: React.FC<RouteWithLayoutProps> = (props) => {
  const { layout: Layout, component: Component, protect, roles, ...rest } = props;
  const { t } = useTranslation();
  const isLoggedIn = useSelector(IsLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);
  const { rd } = parsedQuery;
  const token = localStorage.getItem('jwt') as string;
  const decodeToken = token
    ? (jwt_decode(token) as { type: string; roles: string[] })
    : ({} as { type: string; roles: string[] });
  const getFirstRoute = useCallback(() => {
    const protectedRoute = routes.filter((i) => i.Protected);
    return protectedRoute[0].routePath;
  }, []);

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!protect) {
          if (isLoggedIn) {
            return (
              <Redirect
                to={{
                  pathname: typeof rd === 'string' ? rd : getFirstRoute(),
                }}
              />
            );
          }
          return (
            <Layout>
              <Suspense fallback={<></>}>
                <Component {...matchProps} />
              </Suspense>
            </Layout>
          );
        } else {
          if (isLoggedIn && decodeToken) {
            if (roles && !roles.includes(decodeToken.roles[0])) {
              dispatch(
                commonRequestError({
                  message: t('no_permission_access'),
                  description: t('no_permission_access_desc'),
                  timeout: 1000,
                }),
              );
              return (
                <Layout>
                  <Suspense fallback={<></>}></Suspense>
                </Layout>
              );
            }
            return (
              <Layout>
                <Suspense fallback={<></>}>
                  <Component {...matchProps} />
                </Suspense>
              </Layout>
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                search: `?rd=/${matchProps.location.pathname.slice(1)}`,
                state: { rd: matchProps.location },
              }}
            />
          );
        }
      }}
    />
  );
};

import {
  BitcoinConvertIcon,
  BitcoinRefreshIcon,
  BuildingIcon,
  BuyCryptoIcon,
  CrownIcon,
  DashboardIcon,
  DiscountCircleIcon,
  DocumentTextIcon,
  DollarSquareIcon,
  Money4Icon,
  MoneyReceiveIcon,
  PercentageSquareIcon,
  Profile2UserIcon,
  UserTickIcon,
  Wallet3Icon,
} from '@/assets';
import {
  ADMIN_TRANSACTION_HISTORY_ROUTER,
  BALANCE_REPORT_ROUTER,
  COMMISSION_CLAIM_ROUTER,
  COMMISSION_REPORT_ROUTER,
  DASHBOARD_ROUTER,
  HISTORY_LIST_ROUTER,
  MARKETING_COMPANY_ROUTER,
  MEMBER_LIST_ROUTER,
  SALE_REPORT_ROUTER,
  TRANSACTION_REQUEST_TRACKING_ROUTER,
  USER_LIST_ROUTER,
  USER_TRANSACTION_HISTORY_ROUTER,
  VIP_PACKAGE_TRAKING_ROUTER,
  WALLET_LIST_ROUTER,
  WITHDRAWAL_REQUESTS_ROUTER,
} from '@/routes/constants';
import i18n from '@/i18n';
import { ERoleAdmin } from '@/constants';
export const menuSidebar = [
  {
    id: 1,
    is_group: true,
    title: i18n.t('User management'),
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
    items: [
      {
        id: 1,
        title: i18n.t('Dashboard'),
        path: DASHBOARD_ROUTER,
        icon: DashboardIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
      },
      {
        id: 2,
        title: i18n.t('User list'),
        path: USER_LIST_ROUTER,
        icon: Profile2UserIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
      },
      {
        id: 3,
        title: i18n.t('Member list'),
        path: MEMBER_LIST_ROUTER,
        icon: UserTickIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN, ERoleAdmin.MARKETING_ADMIN],
      },
    ],
  },
  {
    id: 2,
    is_group: true,
    title: i18n.t('Affiliate marketing'),
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
    items: [
      {
        id: 1,
        title: i18n.t('Marketing company'),
        path: MARKETING_COMPANY_ROUTER,
        icon: BuildingIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
      {
        id: 2,
        title: i18n.t('Commission claim'),
        path: COMMISSION_CLAIM_ROUTER,
        icon: DollarSquareIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
    ],
  },
  {
    id: 3,
    is_group: true,
    title: i18n.t('Request tracking'),
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
    items: [
      {
        id: 1,
        title: i18n.t('Withdrawal requests'),
        path: WITHDRAWAL_REQUESTS_ROUTER,
        icon: MoneyReceiveIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
      {
        id: 2,
        title: i18n.t('VIP package tracking'),
        path: VIP_PACKAGE_TRAKING_ROUTER,
        icon: CrownIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
    ],
  },
  {
    id: 4,
    is_group: true,
    title: i18n.t('Hot wallet'),
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
    items: [
      {
        id: 1,
        title: i18n.t('Transaction request tracking'),
        path: TRANSACTION_REQUEST_TRACKING_ROUTER,
        icon: BitcoinRefreshIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
      {
        id: 2,
        title: i18n.t(`Users' transaction history`),
        path: USER_TRANSACTION_HISTORY_ROUTER,
        icon: BuyCryptoIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
      {
        id: 3,
        title: i18n.t(`Admins' transaction history`),
        path: ADMIN_TRANSACTION_HISTORY_ROUTER,
        icon: BitcoinConvertIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
      {
        id: 4,
        title: i18n.t(`Wallet list`),
        path: WALLET_LIST_ROUTER,
        icon: Wallet3Icon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
    ],
  },
  {
    id: 5,
    is_group: true,
    title: i18n.t('Reports'),
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
    items: [
      {
        id: 1,
        title: i18n.t('Balance report'),
        path: BALANCE_REPORT_ROUTER,
        icon: Money4Icon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
      {
        id: 2,
        title: i18n.t('Commission report'),
        path: COMMISSION_REPORT_ROUTER,
        icon: PercentageSquareIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
      {
        id: 3,
        title: i18n.t('Sale report'),
        path: SALE_REPORT_ROUTER,
        icon: DiscountCircleIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
    ],
  },

  {
    id: 6,
    is_group: true,
    title: i18n.t('History log'),
    roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
    items: [
      {
        id: 1,
        title: i18n.t('History log'),
        path: HISTORY_LIST_ROUTER,
        icon: DocumentTextIcon,
        roles: [ERoleAdmin.SUPER_ADMIN, ERoleAdmin.TOP_TRADING_ADMIN],
      },
    ],
  },
];
